<template>
  <b-button
    class="point"
    v-b-tooltip.hover
    :title="doc.title"
    @mousedown="startDrag"
    @mouseup="stopDrag"
    @mousemove="onDrag"
    @click="onClick"
    :style="{ transform: `translate(${pointLeft}px,${pointTop}px)` }"
  >

  </b-button>
</template>

<script>
export default {
  name: 'PointDisplay',
  components: {},
  props: {
    width: Number,
    height: Number,
    doc: Object,
  },
  computed: {
    dimensions() {
      const wr = this.width / this.imageWidth;
      const hr = this.height / this.imageHeight;
      const ratio = Math.min(wr, hr);
      const realWidth = this.imageWidth * ratio;
      const realHeight = this.imageHeight * ratio;
      const offsetWidth = (this.width - realWidth) / 2;
      const offsetHeight = (this.height - realHeight) / 2;
      return {
        realWidth,
        realHeight,
        offsetWidth,
        offsetHeight,
      };
    },
  },
  data() {
    return {
      dragging: false,
      pointLeft: 0,
      pointTop: 0,
      dragStartX: 0,
      dragStartY: 0,
      imageWidth: 3508,
      imageHeight: 2480,
      pointWidth: 36,
    };
  },
  methods: {
    startDrag(event) {
      if (!event.ctrlKey) return;
      this.dragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      document.querySelector('.tooltip').style.display = 'none';
    },
    stopDrag() {
      this.dragging = false;
      const doc = {
        point_x: this.doc.point_x,
        point_y: this.doc.point_y,
      };
      this.$http
        .put(`/template_doc/${this.doc.id}`)
        .send({ doc })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to move doc: ${err.response.text}`);
        });
    },
    onDrag(event) {
      if (!this.dragging) return;
      const deltaX = event.clientX - this.dragStartX;
      const deltaY = event.clientY - this.dragStartY;
      this.pointLeft += deltaX;
      this.pointTop += deltaY;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.doc.point_x = (
        (this.pointLeft - this.dimensions.offsetWidth + (this.pointWidth / 2))
        / this.dimensions.realWidth
      );
      this.doc.point_y = (
        (this.pointTop - this.dimensions.offsetHeight + (this.pointWidth / 2))
        / this.dimensions.realHeight
      );
    },
    onClick(event) {
      if (event.ctrlKey) return;
      this.$emit('click', event);
      this.$emit('open', this.doc);
    },
    calculatePosition() {
      this.pointLeft = (
        this.dimensions.offsetWidth
        + (this.doc.point_x * this.dimensions.realWidth - (this.pointWidth / 2))
      );
      this.pointTop = (
        this.dimensions.offsetHeight
        + (this.doc.point_y * this.dimensions.realHeight - (this.pointWidth / 2))
      );
    },
  },
  created() {
    this.calculatePosition();
  },
  watch: {
    width() {
      this.calculatePosition();
      setTimeout(() => {
        this.calculatePosition();
      }, 500);
    },
    height() {
      this.calculatePosition();
      setTimeout(() => {
        this.calculatePosition();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.point {
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: rgba(59, 130, 246, 0.5);
  border: 4px solid rgba(59, 130, 246, 1);
  border-radius: 50%;
  opacity: 0.4;

  &:hover, &:active {
    cursor: pointer;
    background-color: rgba(59, 130, 246, 1) !important;
    opacity: 0.8;
    border-color: rgba(59, 130, 246, 1) !important;
  }

  &:hover:before {
    content: '';
    position: absolute;
    top: -38px;
    left: -38px;
    width: 100px;
    height: 100px;
  }
}
</style>
